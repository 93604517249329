<template>
	<div class="grid">
		<div class="col-12">
			<div class="card">
                <Loading v-if="loading"/>
                <h1>Configuração inicial do Hub</h1>
                <div class="grid">
                    <div class="col-12">
                        <h2>Adicionar ou Importar Produtos</h2>
                        <div class="grid">
                            <div class="col-9">
                                Importe ou cadastre seus produtos para começar utilizar nosso Hub
                            </div>
                            <div class="col-3">
                                <button @click="displayImportarCadastrar = true">Importar</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="grid">
                    <div class="col-12">
                        <h2>Tipo de Im</h2>
                        <div class="grid">
                            <div class="col-9">
                                Importe ou cadastre seus produtos para começar utilizar nosso Hub
                            </div>
                            <div class="col-3">
                                <button @click="displayImportarCadastrar = true">Importar</button>
                            </div>
                        </div>
                    </div>
                </div>
			</div>
		</div>
	</div>
    
    <Dialog header="Adicionar Produto" v-model:visible="displayImportarCadastrar" >
        <div class="flex flex-column">
            <div class="flex">
                <div class="col-12">
                    
                    <button>
                        <p>Manual</p>
                        fazer o cadastro de produtos manualmente
                    </button>
                </div>
            </div>
            <div class="flex">
                <div class="col-12">
                    <button @click="importarViaPlanilha()">
                        <p>Importar via Planilha</p>
                    </button>
                </div>
            </div>
            <div class="flex">
                <div class="col-12">                    
                    <button>
                        <p>Importar via Ecommerce</p>
                    </button>
                </div>
            </div>
            <div class="flex">
                <Button label="Adicionar" class="p-button-success" @click="adicionarAnuncio()"/> 
                <Button label="Cancelar" class="p-button-danger" @click="limparFormProduto()"/> 
            </div>
        </div>
    </Dialog>
    <Toast position="top-left" />

</template>

<script>

import Api from '../service/api';
import Loading from '../components/Loading.vue';
import { useRoute } from 'vue-router'
	export default {
    setup(){
        const router = useRoute()
        return{
            router
        }
    },
    data() {
        return {
            msg_fim_sessao:'A sessão expirou. Faça Login para voltar ao Hub.',
			msg_erro:'Erro ao se comunicar com o servidor. Verifique sua internet e tente novamente. Caso o problema persista entre em contato com o suporte.',
            api: "",
            loading: false,
            produtos: null,
            pagina: "1",
            quantidadePorPagina: "10",
            primeiroItemDaPagina: 1,
            ordenar: "id",
            tipoOrdenar: "DESC",
            totalLinhas: null,
            quantidades: ["10", "20", "50"],
            displayImportarCadastrar:false,
            mostrarAdicionar: false,
        };
    },
    produtosService: null,
    quantidadePaginas: 1,
    created() {
        this.api = new Api();
        this.api.qtdAnuncios().then(data => { this.totalLinhas = data.data.quantidade 
        }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
    },
    mounted() {
        this.loading = false;
    },
    methods: {
        importarViaPlanilha(){
            this.$router.push({ name: 'importacao_produto_planilha' })
        }
    },
    components: { Loading }
}
</script>

<style scoped lang="scss">
@import '../assets/demo/badges.scss';
</style>
<style scoped lang="css">
input{
    margin: 10px;
}
.marketplace{
    width:33%;
    display: inline-block;
    padding:15px;
}

.card_marketplace{
    width:100%;
    padding:14px;

    border-radius: 5px;
    border: #000 solid 1px;
}

.titulo_marketplace{
    font-size: 14pt;
    font-weight: bold;
}

.informacoes_anuncios .numero{
    font-size: 20pt;
    font-weight: bold;
    margin-bottom:0;
    margin-top:10px;
}
.informacoes_anuncios .titulo_informacoes{
    font-size: 12pt;
    font-weight: bold;
}
</style>
